import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import loading from "../assets/loading.gif"
import help_icon from "../assets/help.svg"


const ShareAssistantModal = ({ isOpen, onClose, user,itemId,itemName }) => {

    const [emailInput, setEmailInput] = useState('');
    const [emails,setEmails] = useState([]);
    const [sharing, setSharing] = useState(false);
    const [permission, setPermission] = useState('Member');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [warningMessage,setWarningMessage] = useState('');
    const navigate = useNavigate();



    const handleEmailChange = (e) => {
        setEmailInput(e.target.value);
      };
    
    const handleInvite = () => {
        const emailArray = emailInput.split(',').map(email => email.trim()).filter(email => email);
        setEmails(emailArray)
        console.log('Invited emails:', emailArray);
        console.log('Selected permission:', permission);
        const userDomain = user.email.split('@')[1];

        const differentDomainEmails = emailArray.filter(email => {
            const domain = email.split('@')[1];
            return domain !== userDomain;
          });
      
          if (differentDomainEmails.length > 0) {
            setWarningMessage(`Warning: Some emails do not match your domain (${userDomain}): ${differentDomainEmails.join(', ')}`);
          } else {
            setWarningMessage('');
            setSharing(true)
          }
 
        // Implement actual invite logic here
      };
    

    useEffect(()=>{
        if (sharing) {

            fetch('https://api.qquest.io/share-assistant', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${user.token}`,
    
                },
                body: JSON.stringify({
                    user_id:user.uid,
                    user_email:user.email,
                    audience:emails,
                    assist_id:itemId,
                    assistantName:itemName,
                    role:permission
                })
              })
              .then(res => res.json())
              .then(data => {
                console.log(data);
                setSharing(false)
                setSuccessMessage('The assistant has been shared successfully.');
              })
              .catch(error => {
                console.log(JSON.stringify(error));
                setSharing(false)
                setErrorMessage( "Cannot share the assistant.");
              });
    

          }
    },[user,emails,permission,itemId,itemName,sharing])


    return (
        <>
        { isOpen &&  <div>
<div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="assistant-modal">
    <div className="relative top-20 mx-auto p-5 px-10 border w-full max-w-4xl shadow-lg rounded-md bg-white">
        <div className="relative mt-3 mb-8 flex items-center justify-between">
            <h1 className="text-lg font-bold text-gray-900 ">Share "{itemName}" with:</h1>
            <button onClick={onClose} className="ml-4 flex-shrink-0">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
            </button>
        </div>


            <div>
                <div className="mb-2 flex items-center">
                    <input
                        type="text"
                        value={emailInput}
                        onChange={handleEmailChange}
                        className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter email addresses, separated by commas"
                    />
                    <select className="ml-4  px-2 py-1 text-sm flex-1 border rounded-md shadow-sm" value={permission} onChange={e => setPermission(e.target.value)}>
                        <option className='text-gray-400' value = "Member" >Member</option>
                        <option className='text-gray-400' value = "Admin" >Admin</option>
                    </select>
                    <div className="relative group ml-2">
                        <img src={help_icon} alt="Edit Icon" className="w-auto h-auto object-cover" />
                        <div className="absolute bottom-full mb-2 hidden group-hover:block bg-black text-white text-xs rounded px-3 py-1 whitespace-nowrap">
                            <p>Admin can edit and use the assistant;</p>
                            <p>Member can only use the assistant.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-end mt-6">
                <button
                    onClick={onClose}
                    className="flex items-center bg-gray-300 border border-gray-200 px-2 py-1 mt-1 mb-1 mr-1 rounded-md shadow w-50"
                >
                    Cancel
                </button>
                <button
                    onClick={handleInvite}
                    className="flex items-center bg-[#F58F29] border border-gray-200 px-2 py-1 mt-1 mb-1 ml-1 rounded-md shadow w-50"
                >
                    Invite
                </button>
            </div>

           
        {warningMessage && <p className="flex items-center  text-red-800 p-2 rounded-md mb-4 text-sm">{warningMessage}
            <span
                className={`ml-2 text-blue-500 hover:text-blue-700 cursor-pointer ${sharing || successMessage || errorMessage ? 'text-gray-500' : ''}`}
                onClick={() => setSharing(true)}
                >
                Continue
            </span> 
        </p>}
        {sharing && <div className='flex items-center'>
                        <img src={loading} alt="loading" className='w-12 ' />
                        <p className='text-gray-800 text-sm ml-2'>Sharing the assistant...</p>
                        </div>}
        {successMessage && <p className="flex items-center bg-green-200 text-green-800 p-2 rounded-md mb-4 text-sm">{successMessage}</p>}
        {errorMessage && <p className="flex items-center bg-red-200 text-red-800 p-2 rounded-md mb-4 text-sm">{errorMessage}</p>}
    </div>
</div>

                
                
            </div>}
        </>

    );
};


export default ShareAssistantModal;