import React, {useState,useEffect } from 'react';
import uploadfile from "../assets/uploadfile.png";
import MySQLlogo from "../assets/MySql.png";
import postgreSQLlogo from "../assets/postrgreSQL.png";
import SQLServerlogo from "../assets/sqlserver.png";
import snowflakelogo from "../assets/snowflake.png";
import redshiftlogo from "../assets/amazon redshift.png";
import bigQuerylogo from "../assets/bigQuery.png";
import sheetlogo from "../assets/googlesheet.png";
import loading from "../assets/loading.gif"

const dataSources = [
    { id: 'csv', image: uploadfile, alt: 'csv' },
    { id: 'MySQL', image: MySQLlogo, alt: 'MySQL' },
    { id: 'PostgreSQL', image: postgreSQLlogo, alt: 'PostgreSQL' },
    { id: 'SQL Server', image: SQLServerlogo, alt: 'SQL Server' },
    { id: 'Snowflake', image: snowflakelogo, alt: 'Snowflake' },
    { id: 'Amazon Redshift', image: redshiftlogo, alt: 'Amazon Redshift' },
    { id: 'Big Query', image: bigQuerylogo, alt: 'bigQuery' },
    { id: 'sheet', image: sheetlogo, alt: 'GoogleSheet' }
];


const AddDataSourceModal = ({ isOpen, onClose, user }) => {
    const [showList, setShowList] = useState(true);
    const [showFile, setShowFile] = useState(false);
    const [showClassicSQL, setShowClassicSQL] = useState(false);
    const [showBigQuery, setShowBigQuery] = useState(false);
    const [showSheet, setShowSheet] = useState(false);
    const [sql_type,setSQLType] = useState("");

    const [host, setHost] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [database, setDatabase] = useState('');
    const [schema, setSchema] = useState('');
    const [port, setPort] = useState('');
    const [credential, setCredential] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [datasetName, setDatasetName] = useState('');
    const [connecting,setConnecting] = useState(null)

    const formData = new FormData();



    //if (!isOpen) return null;
    //console.log(user.uid)
    

/*
    const AddDataSource = (e) => {
        setShowList(false)
        //console.log('Current Target:', e.currentTarget); // Should log the div element
        //console.log('Current Target:', e.currentTarget.id); // Should log the div element
        setSQLType(e.currentTarget.id);
        if (sql_type === "MySQL" || sql_type === "PostgreSQL" || sql_type === "SQL Server" || sql_type === "Snowflake" || sql_type === "Amazon Redshift") {
            setShowClassicSQL(true)
            console.log(sql_type);
        } else if(sql_type === "Big Query") { 
            setShowBigQuery(true)
            console.log(sql_type);
        } else if (sql_type === "csv") {
            setShowFile(true)
            console.log(sql_type);
        } else if (sql_type === "sheet"){ ////needs review
            setShowSheet(true)
            console.log(sql_type);
        }
    };

*/

const AddDataSource = (e) => {
    setShowList(false);
    setSQLType(e.currentTarget.id);
};

useEffect(() => {
    if (!showList) {
        if (sql_type === "MySQL" || sql_type === "PostgreSQL" || sql_type === "SQL Server" || sql_type === "Snowflake" || sql_type === "Amazon Redshift") {
            setShowClassicSQL(true);
        } else if (sql_type === "Big Query") {
            setShowBigQuery(true);
        } else if (sql_type === "csv") {
            setShowFile(true);
        } else if (sql_type === "sheet"){ ////needs review
            setShowSheet(true);
        }
    }
}, [showList, sql_type]);

    const handleFileChange = (e) => {
        
        const field = e.currentTarget.id;
        console.log(field);
        if (field === "credentials-bigquery") {
            const selectedFile = e.target.files[0];
            const reader = new FileReader();

            reader.onload = (event) => {
            const fileContent = event.target.result;
            console.log('File content:', fileContent);
            // Do something with the file content, such as storing it in state
            setCredential(fileContent);
            };
        
            reader.readAsText(selectedFile,'utf8'); // Read file as text
        } else {
            const fileInput = e.target;
            const maxFiles = 5;
            const maxSize = 10 * 1024 * 1024; // 100 MB in bytes

            if (fileInput.files.length > maxFiles) {
                alert(`You can only upload a maximum of ${maxFiles} files.`);
                e.target.value = ''; // Reset file input
                return;
            }


            for (let i = 0; i < fileInput.files.length; i++) {
                if (fileInput.files[i].size > maxSize) {
                    alert(`Each file must be smaller than 10 MB.`);
                    e.target.value = ''; // Reset file input
                    return;
                }
                formData.append("files", fileInput.files[i]);
            }

        }
        
      };

    const close = (e) => {
        onClose();
        setShowList(true);
        setShowFile(false)
        setShowClassicSQL(false);
        setShowBigQuery(false)
        setShowSheet(false)        
    };
    const back = (e) => {
        setShowList(true);
        setShowFile(false)
        setShowClassicSQL(false);
        setShowBigQuery(false)
        setShowSheet(false)        
    };

    const DataConnect = (e) => {
        e.preventDefault();
        console.log(credential)
        setConnecting(true)
        let requestDetails = {};
        if (sql_type === "MySQL" || sql_type === "PostgreSQL" || sql_type === "SQL Server" || sql_type === "Snowflake" || sql_type === "Amazon Redshift") {
            requestDetails = JSON.stringify({
                user_id: user.uid,
                email: user.email,
                type: "Connect Database",
                database_type: sql_type,
                source: {
                    host: host,
                    user: username,
                    password: password,
                    database: database,
                    schema: schema,
                    port: port
                }
            });
        } else if (sql_type === "Big Query") {
            requestDetails = JSON.stringify({
                user_id: user.uid,
                email: user.email,
                type: "Connect Database",
                database_type: sql_type,
                source: { 
                    database: database,
                    schema: schema,
                    credentials: JSON.parse(credential)
                }
            })
        }

        //console.log(requestDetails);

        fetch('https://api.qquest.io/connect-to-database', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.token}`,

            },
            body: requestDetails
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            setConnecting(false)
            setSuccessMessage('The data source is connected successfully.');
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            setConnecting(false)
            setErrorMessage( "Cannot connect to this data source. Please RE-LOGIN and try to add a new data source.");
          });
    };

    const FileConnect = (e) => {
        e.preventDefault();
        setConnecting(true)
        formData.append('datasetName',datasetName);
        console.log(formData)

        fetch( 'https://api.qquest.io/upload-local-source', {
            // mode: 'no-cors',
             method:'POST',
             body: formData
         })
         .then(response => response.text())
         .then(data => {
             console.log(data);
             setConnecting(false)
             setSuccessMessage('The data source is connected successfully.');
         })
         .catch(error => {
            setConnecting(false)
            setErrorMessage( "Cannot connect to this data source. Please RE-LOGIN and try to add a new data source.");
         })

    };

    return (
       <>
        { isOpen && <div style={{
            position: 'fixed', 
            top: 0, 
            left: 0, 
            right: 0, 
            bottom: 0, 
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
            }}>

            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="assistant-modal">
                <div className="relative top-20 mx-auto p-5 px-10 border w-full max-w-4xl shadow-lg rounded-md bg-white">
                    <div className="relative mt-3 mb-3 flex items-center justify-between">
                        <h1 className="text-lg font-bold text-gray-900 ">Add New Data Source</h1>
                        <button onClick={close} className="ml-4 flex-shrink-0">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </button>
                    </div>
                
        { showList  ? (
                    <div className="grid grid-cols-[repeat(4,_25%)] grid-rows-[repeat(2,_auto)] gap-2">
                        {dataSources.map((source) => (
                            <div key={source.id} className="col-span-1 row-span-1 bg-white border border-blue-100 rounded-lg flex justify-center items-center p-5 m-2" id={source.id} onClick={AddDataSource}>
                                <img src={source.image} alt={source.alt} />
                            </div>
                        ))}
                    </div>
                    
            ) : showFile? (
                <div className ="databaseModalContent" id="databaseModalContent">
                    
                    <span className="text-sm font-bold text-gray-900 "><strong>Connection Set up</strong></span> <br/><br/>
               
                    <form className = "databaseForm" id="databaseForm-csv" onSubmit={FileConnect}>
                        <div className="form-group">
                            <label htmlFor="dataset">Name your dataset:</label>
                            <input type="text" id="dataset-name" name="dataset-name" value={datasetName} onChange={(e) => setDatasetName(e.target.value)} required/>
                        </div>
                        
                        <div className="flex items-center ">
                            <label htmlFor="file">Files:</label>
                            <div className="tooltip">   
                                <span className="tooltip-icon">&#x3F;</span>
                                <span className="tooltiptext">Limit your upload to a maximum of 5 files, with each file not exceeding 10MB in size.</span>
                            </div><br/>
                            
                        </div>
                        <input type="file" id="fileInput" name="files" onChange={handleFileChange} required multiple/>
                        
                        
                        <div className="relative mt-3 mb-3 flex items-center justify-between">
                                <p className="text-grey-200 cursor-pointer hover:underline" onClick={back}>Back</p>
                                <span className="bg-white text-gray-700 p-2  bottom-full left-0 ml-2 mt-2 w-40">Need help? <a href="https://www.qquest.io/integration" id="tutorial-link" className="text-blue-500 hover:text-blue-700"> Integration</a></span><br/><br/>
                                <button className="flex items-center bg-[#F58F29] border border-gray-200 p-1 mt-1 mb-1 rounded-md shadow w-50" type="submit" value="Connect">
                                    <span className="text-white">Connect</span>
                                </button>
                        </div>
                    </form>
                    {connecting && <div className='flex items-center'>
                        <img src={loading} alt="loading" className='w-12 ' />
                        <p className='text-gray-800 text-sm'>Connecting the data source</p>
                        </div>}
                    {successMessage && <p className="bg-green-200 text-green-800 p-2 rounded-md mb-4">{successMessage}</p>}
                    {errorMessage && <p className="bg-red-200 text-red-800 p-2 rounded-md mb-4">{errorMessage}</p>}
            </div>

            ) : showClassicSQL? (
               
                    <div className="databaseModalContent" id="databaseModalContent">
                        
                        <span className="text-sm font-bold text-gray-900 "><strong>Connection Set up</strong></span> <br/><br/>
                        
                        <form className="databaseForm" id="databaseForm" onSubmit={DataConnect}>
                            <label htmlFor="host">Host:</label>
                            <input type="text" id="host" name="host" value={host} onChange={(e) => setHost(e.target.value)} required/>
                            
                            <label htmlFor="user">User:</label>
                            <input type="text" id="user" name="user" value={username} onChange={(e) => setUsername(e.target.value)} required/>
                            
                            <label htmlFor="password">Password:</label>
                            <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                            
                            <label htmlFor="database">Database:</label>
                            <input type="text" id="database" name="database" value={database} onChange={(e) => setDatabase(e.target.value)} required/>
                            
                            <label htmlFor="schema">Schema: (Optional)</label>
                            <input type="text" id="schema" name="schema" value={schema} onChange={(e) => setSchema(e.target.value)}/>
                            
                            <label htmlFor="port">Port: (Optional)</label>
                            <input type="text" id="port" name="port" value={port} onChange={(e) => setPort(e.target.value)}/>

                            <div className="relative mt-3 mb-3 flex items-center justify-between">
                                <p className="text-grey-200 cursor-pointer hover:underline" onClick={back}>Back</p>
                                <span className="bg-white text-gray-700 p-2  bottom-full left-0 ml-2 mt-2 w-40">Need help? <a href="https://www.qquest.io/integration" id="tutorial-link" className="text-blue-500 hover:text-blue-700"> Integration</a></span><br/><br/>
                                <button className="flex items-center bg-[#F58F29] border border-gray-200 p-1 mt-1 mb-1 rounded-md shadow w-50" type="submit" value="Connect">
                                    <span className="text-white">Connect</span>
                                </button>
                            </div>
                        </form>
                        {connecting && <div className='flex items-center'>
                        <img src={loading} alt="loading" className='w-12 ' />
                        <p className='text-gray-800 text-sm'>Connecting the data source</p>
                        </div>}
                        {successMessage && <p className="bg-green-200 text-green-800 p-2 rounded-md mb-4">{successMessage}</p>}
                        {errorMessage && <p className="bg-red-200 text-red-800 p-2 rounded-md mb-4">{errorMessage}</p>}
                    </div>

            ) : showBigQuery ? (
                <div className ="databaseModalContent" id="databaseModalContent">
                    
                    <span className="text-sm font-bold text-gray-900 "><strong>Connection Set up</strong></span> <br/><br/>

                    <form className = "databaseForm" id="databaseForm-bigquery" onSubmit={DataConnect}>
                        <div className="form-group">
                            <label htmlFor="database">Database:</label>
                            <input type="text" id="database-bigquery" name="database" value={database} onChange={(e) => setDatabase(e.target.value)} required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="schema">Schema:</label>
                            <input type="text" id="schema-bigquery" name="schema" value={schema} onChange={(e) => setSchema(e.target.value)} required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="credentials">Credentials:</label>
                            <input type="file" id="credentials-bigquery" name="credentials" onChange={handleFileChange}  required/>
                        </div>
                        <div className="relative mt-3 mb-3 flex items-center justify-between">
                                <p className="text-grey-200 cursor-pointer hover:underline" onClick={back}>Back</p>
                                <span className="bg-white text-gray-700 p-2  bottom-full left-0 ml-2 mt-2 w-40">Need help? <a href="https://www.qquest.io/integration" id="tutorial-link" className="text-blue-500 hover:text-blue-700"> Integration</a></span><br/><br/>
                                <button className="flex items-center bg-[#F58F29] border border-gray-200 p-1 mt-1 mb-1 rounded-md shadow w-50" type="submit" value="Connect">
                                    <span className="text-white">Connect</span>
                                </button>
                        </div>
                    </form>
                    {connecting && <div className='flex items-center'>
                        <img src={loading} alt="loading" className='w-12 ' />
                        <p className='text-gray-800 text-sm'>Connecting the data source</p>
                        </div>}
                    {successMessage && <p className="bg-green-200 text-green-800 p-2 rounded-md mb-4">{successMessage}</p>}
                    {errorMessage && <p className="bg-red-200 text-red-800 p-2 rounded-md mb-4">{errorMessage}</p>}
            </div>
            ) : showSheet &&(
                <div>
                    <p>this is coming</p>
                </div>
            ) 
        }
            

            </div> 
                
            </div>   

    </div>}
    </>
)
    ;
} 


export default AddDataSourceModal;