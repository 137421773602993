import { useCallback } from 'react';

const useFetchAnswers = () => {
  const fetchAnswers = useCallback(async (user, navigate, database,question, setSQLCode,setMessageId,setMessages,setRawData,assistantId,source_id=null) => {
    const fetchWithRetry = async (url, options, retries = 3, delay = 1000) => {
      for (let i = 0; i < retries; i++) {
        try {
          const controller = new AbortController();
          const id = setTimeout(() => controller.abort(), options.timeout);
          const response = await fetch(url, {
            ...options,
            signal: controller.signal
          });
          clearTimeout(id);
          if (!response.ok) throw new Error('Server response wasn\'t OK');
          return await response.json();
        } catch (error) {
          console.log(`Attempt ${i + 1}: Retrying after error: ${error.message}`);
          if (i < retries - 1) {
            await new Promise(resolve => setTimeout(resolve, delay));
          } else {
            throw error;
          }
        }
      }
    };  

        if (user && user.uid) {
          console.log("check whether user logged in in answer fetch:", user.uid);
          ///need to add bearer token later
          try {
          const response = await fetch('https://api.qquest.io/data-source-selected-from-chat-view', {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ 
              user_id:user.uid,
              Email: user.email ,
              assist_id:assistantId,
              database: database,
              question: question,
              request_source:"Train Assistant",
              source_id: source_id
            }),
            timeout: 10000 // Setting a 10-second timeout
          });
          const data = await response.json();
          setSQLCode(JSON.parse(data)["SQL Code"]);
          setMessageId(JSON.parse(data).message_id);
          setMessages(JSON.parse(data).content);
          setRawData(JSON.parse(data)['Raw Data']);

          console.log(data)
          console.log(JSON.parse(data)["SQL Code"])
        } catch (error) {
          console.log("Error fetching assistants", error);
        }
        } else {
          navigate('/login');
        }
    }, []);

  return fetchAnswers;
};

export default useFetchAnswers;
