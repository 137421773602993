import button_plus from "../assets/button_white.svg";
import Modal from "./AddDataSourceModal";
import React, { useEffect, useState } from "react";
import { useAuth } from '../hooks/useAuth';
import useFetchAssistants from '../hooks/useFetchAssistants';
import useFetchDataSources from '../hooks/useFetchDataSources';
import { useNavigate, Link } from 'react-router-dom';
import Modal_Assistant from "./CreateAssistantsModal"


const DashboardDefault = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isAssisModalOpen,setAssisModalOpen] = useState(false);
    const { user,loading } = useAuth();
    const [datasource, setDatasource] = useState([]);
    const navigate = useNavigate();
    const [assistants, setAssistants] = useState([]);

    const openModal = () => setModalOpen(true);
    const closeModal = () => {
      setModalOpen(false);
      window.location.reload();
    };

    const openAssistModal = () => setAssisModalOpen(true);
    const closeAssistModal = () => {
      setAssisModalOpen(false);
      window.location.reload();
    };

    useFetchDataSources(user, navigate, setDatasource);
    useFetchAssistants(user, navigate, setAssistants);
  
    return (
        <div className="flex justify-center items-center min-h-screen">
  <div className="flex flex-col items-center text-center px-4">
    <p className="text-3xl font-bold text-gray-800 mb-4">Welcome, {user.first_name}!</p>
    {
      (datasource.length === 0 && assistants.length === 0) && (
        <>
          <p className="text-lm text-gray-700 mb-6">Let's get started by adding a data source.</p>
          <button className="flex items-center bg-[#F58F29] border border-gray-200 p-1 mt-1 mb-1 rounded-md shadow w-50" onClick={openModal}>
            <img src={button_plus} alt='Add Data Source' className='w-5 h-5 mr-2'/>
            <p className="text-white">Add Data Source</p>
          </button>
          <Modal isOpen={isModalOpen} onClose={closeModal} user={user}>
                <p>This is the content of the modal</p>
        </Modal>
        </>
      )
    }
    {
      (datasource.length > 0 && assistants.length === 0) && (
        <>
          <p className="text-lm text-gray-700 mb-6">You have data! Now let's create an assistant.</p>
          <button className="flex items-center justify-center bg-[#F58F29] border border-gray-200 p-1 mt-1 mb-1 rounded-md shadow w-[200px]" onClick={openAssistModal}>
            <img src={button_plus} alt='Create assistant' className='w-5 h-5 mr-2'/>
            <p className="text-white">Create assistant</p>
        </button>
        <Modal_Assistant isOpen={isAssisModalOpen} onClose={closeAssistModal} user={user}>
                <p>This is the content of the modal</p>
        </Modal_Assistant>
        </>
      )
    }
    {
      (datasource.length === 0 && assistants.length > 0) && (
        <p className="text-lm text-gray-700 mb-6">
        Someone shared an assistant with you, <Link to="/dashboard/assistants" className="text-blue-500 hover:text-blue-700">check it out!</Link>
      </p>
      )
    }
    {
      (datasource.length > 0 && assistants.length > 0) && (
        // Assuming you have a function to navigate, e.g., using React Router or another method
        // This could redirect immediately or perhaps on some user action like a button click
        window.location.href = "/dashboard/assistants" // Change to your target URL or route handling
        // <p>it's done</p>
      )
    }

  </div>
</div>

      
    );
  };
  
  export default DashboardDefault;
