import { useEffect } from 'react';

const useFetchAssistants = (user, navigate, setAssistants,itemID=null) => {
  useEffect(() => {
    const fetchAssistants = async () => {
      try {
        if (user && user.uid) {
          console.log("check whether user logged in in assistants fetch:", user.uid);
          const response = await fetch('https://api.qquest.io/assistants-list-portal', {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ UserId: user.uid ,itemID:itemID}),
          });
          const data = await response.json();
          setAssistants(data.data);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.log("Error fetching data sources:", error);
      }
    };

    fetchAssistants();
  }, [user, navigate, setAssistants]); // Only rerun if user, navigate, or setDatasource changes
};

export default useFetchAssistants;
