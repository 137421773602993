import { useEffect } from 'react';

const useFetchDataModels = (user, navigate, setDataModel,itemID=null) => {
  useEffect(() => {
    const fetchDataModels = async (retries = 3) => {
      try {
        const controller = new AbortController();
        const timeout = setTimeout(() => controller.abort(), 10000); // Set timeout (10s)
        console.log("time setup:", timeout)

        if (user && user.uid) {
          console.log("check whether user logged in data model fetch:", user.uid);
          console.log(itemID)
         
          const response = await fetch('https://api.qquest.io/get-enriched-database-schema', {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ UserId: user.uid,source_id:itemID }),
          });
          const data = await response.json();
          //  console.log(data)
          setDataModel(data);
        } else {
          navigate('/login');
        }

        clearTimeout(timeout);

      } catch (error) {
        if (retries > 0) {
          console.log(`Retrying... (${3 - retries + 1})`);
          setTimeout(() => fetchDataModels(retries - 1), 2000); // Retry after 2 seconds
        } else {
        console.log("Error fetching data sources:", error);
      }}
    };

    fetchDataModels();
  }, [user, navigate, setDataModel,itemID]); // Only rerun if user, navigate, or setDatasource changes
};

export default useFetchDataModels;
