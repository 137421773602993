import React, { useState , useEffect} from 'react';
import { useAuth } from '../hooks/useAuth'; // Adjust the path as necessary
import qquest_icon from "../assets/logo.png";
import { useNavigate,useLocation } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("");
    const navigate = useNavigate(); 
    const { signIn } = useAuth(); // Using the signIn method from context
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const emailParam = params.get('email');
        if (emailParam) {
            setEmail(emailParam);
        }
    }, [location]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await signIn(email, password); // Use the signIn method from the context
            setLoginError(""); // Clear any previous errors
        } catch (error) {
            setLoginError(error.message); // Handle errors such as invalid credentials
        }
    };

    return (
        <div className="flex items-center w-full mx-auto h-screen bg-[#FAFAFA]">
            <form onSubmit={handleSubmit} className="grid place-items-center lg:w-5/12 sm:w-9/12 w-11/12 mx-auto bg-white text-gray-800 shadow-2xl rounded-3xl">
                <div className='flex items-center mb-4'>
                  <img src={qquest_icon} alt='Qquest' className='w-12 pt-16 pb-4 mr-4'/>
                  <div className="pt-16 pb-4 text-3xl font-bold capitalize logo text-black">Login To Qquest</div>
                </div>
                
                <div className="w-full flex items-center px-14 pb-6">
                    <label className='flex-shrink-0 text-sm font-medium text-gray-800 mt-1 mr-20'>Email</label>
                    <input
                        type="email"
                        className="ml-4 mt-1 px-2 py-1 text-sm flex-1 border rounded-md mr-auto shadow-sm max-w-[542px]"
                        placeholder="example@123.com"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="w-full flex items-center px-14 pb-4">
                    <label className='flex-shrink-0 text-sm font-medium text-gray-800 mt-1 mr-12'>Password</label>
                    <input
                        type="password"
                        className="ml-4 mt-1 px-2 py-1 text-sm flex-1 border rounded-md mr-auto shadow-sm max-w-[542px]"
                        placeholder="********"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                {loginError && <div className = "px-8" style={{ color: 'red', textAlign: 'center' }}>{loginError}</div>}
                <div className="mx-auto flex justify-center items-center pt-6 pb-6">
                    <button
                        type="submit"
                        className="flex items-center justify-center bg-[#F58F29] border border-gray-200 p-1 mt-1 mb-1 rounded-md shadow w-[200px]"
                    >
                        <p className="text-white">Login</p>
                    </button>
                </div>
                <div className="mx-auto flex justify-center items-center pb-16">
                  <div className="text-sm text-blue-600 cursor-pointer hover:underline mx-3" onClick={() => navigate("/forget-password")}>Forget password</div>
                  <p className="text-sm text-gray-800 ">|</p>
                  <div className="text-sm text-blue-600 cursor-pointer hover:underline mx-3" onClick={() => navigate("/register")}>Register to Qquest</div>
   
                </div>
            </form>
        </div>
    );
};

export default Login;
