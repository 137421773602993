import React, {useState } from 'react';



const AddFileModal = ({ isOpen, onClose, user, setUpdateDetails }) => {
    const [selectedFileCategory, setSelectedFileCategory] = useState('');
    const [fileContent, setFileContent] = useState('');
    const [description, setDescription] = useState('');
    const [fileKey,setFileKey] = useState('')
    




    if (!isOpen) return null;
    //console.log(user.uid)
    


    const handleFileChange = (e) => {
        const field = e.currentTarget.id;
        console.log(field);

          const selectedFile = e.target.files[0];
          console.log(selectedFile)
          const key = selectedFileCategory +'/' + user.uid + '/'+selectedFile.name 
          console.log(key)
          setFileKey(key);
          const reader = new FileReader();
    
          reader.onload = (event) => {
            const fileContent = event.target.result;
            //console.log('File content:', fileContent);
            // Do something with the file content, such as storing it in state
            setFileContent(fileContent);
          };
    
          reader.readAsText(selectedFile, 'utf8'); // Read file as text
      };

    const close = (e) => {
        onClose();      
    };

    const FileConnect = (e) => {
        e.preventDefault();
        
        setUpdateDetails({
            type:"Add File",
            key:fileKey,
            content:JSON.parse(fileContent),
            description:description
        })
        console.log("setUpdateDetails done")
        //onClose();  

    };





    return (
        <div style={{
            position: 'fixed', 
            top: 0, 
            left: 0, 
            right: 0, 
            bottom: 0, 
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
            }}>

            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="assistant-modal">
                <div className="relative top-20 mx-auto p-5 px-10 border w-full max-w-4xl shadow-lg rounded-md bg-white">
                    <div className="relative mt-3 mb-3 flex items-center justify-between">
                        <h1 className="text-lg font-bold text-gray-900 ">Add a new file</h1>
                        <button onClick={close} className="ml-4 flex-shrink-0">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </button>
                    </div>
                    <div className="mb-8 flex items-center">
                        <label className="flex-shrink-0  text-sm font-medium text-gray-500 mt-1 mr-14 ">Select File Category</label>
                        <select className="ml-4 mt-1 px-2 py-1 text-sm flex-1 border rounded-md shadow-sm" value={selectedFileCategory} onChange={e => setSelectedFileCategory(e.target.value)}>
                            <option className='text-gray-400'  >Select data source</option>
                            {/* Populate with options here */}
                            
                            <option key='Metric' value='MetricFiles'>Metrics</option>
                            <option key='DataDictionary' value='DictionaryFiles'>Data Dictionary</option>
                            <option key='Others' value='Others'>Others</option>
                            
                        </select>
                        
                    </div>
                <form className="" onSubmit={FileConnect} >
                    {selectedFileCategory==='MetricFiles' ? (
                        <div className="mb-2 flex items-center justify-between">
                        <label className="flex-shrink-0 text-sm font-medium text-gray-500 mt-1 mr-16">Metrics</label>
                        <input type="file" className="ml-4 mt-1 px-2 py-1 flex-1 border rounded-md shadow-sm text-sm max-w-[364px]" id="metrics" onChange={handleFileChange} />
                        <a href="https://drive.google.com/drive/folders/1arLbu1WBaBIAcYLXEU3bTY6d8Aw8lSq_?usp=sharing" className="flex-shrink-0 ml-6 mt-1 p-2 text-blue-500 hover:text-blue-700 text-sm">Download template</a>
                    </div>
                    ) 
                    :selectedFileCategory==='DictionaryFiles' ? (
                        <div className="mb-8 flex items-center  justify-between">
                            <label className="flex-shrink-0 text-sm font-medium text-gray-500 mt-1 mr-3">Data Dictionary</label>
                            <input type="file" className="ml-4 mt-1 px-2 py-1 flex-1 border rounded-md shadow-sm text-sm max-w-[364px]" id="dictionary" onChange={handleFileChange} />
                            <a href="https://drive.google.com/drive/folders/1arLbu1WBaBIAcYLXEU3bTY6d8Aw8lSq_?usp=sharing" className="flex-shrink-0 ml-6 mt-1 p-2 text-blue-500 hover:text-blue-700 text-sm">Download template</a>
                        </div>
                    ) : selectedFileCategory==='Others' && (
                        <div>
                            <div className="mb-8 flex items-center justify-between">
                                <label className="flex-shrink-0 text-sm font-medium text-gray-500 mt-1 mr-3">Others</label>
                                <input type="file" className="ml-4 mt-1 px-2 py-1 flex-1 border rounded-md shadow-sm text-sm max-w-[608px]" id="others" onChange={handleFileChange} />
                            </div>
                            <div className="mb-8 flex items-center justify-between">
                                <label className="flex-shrink-0 text-sm font-medium text-gray-500 mt-1 mr-3">Description</label>
                                <input type="text" className="ml-4 mt-1 px-2 py-1 flex-1 border rounded-md shadow-sm text-sm max-w-[608px]" maxlength="200" placeholder="Enter text here (up to 200 characters)"
                                value={description} onChange={(e) => setDescription(e.target.value)}/>
                            </div>
                        </div>
                    )
                    }

                <div className=" text-sm flex items-center">
                    <button type="submit" className="ml-auto bg-[#F58F29] border border-gray-200 p-1 mt-1 mb-1 rounded-md shadow w-40">
                        <p className="text-center text-white">Add File</p>
                    </button>
                </div>
                </form>
        

                </div> 
                
            </div>   

    </div>
    );
}


export default AddFileModal;