import React from 'react';

const DataTable = ({ data }) => {
  // Get headers dynamically from the first item in the array
  console.log("DataTable runs here:", data)
  const headers = data.length > 0 ? Object.keys(data[0]) : [];

  return (
    <div className=" mx-4 h-32 bg-white   overflow-y-auto ">
    <p className='p-2 text-sm'>Result Preview:</p>
    
    <table  className="min-w-full  bg-white border-collapse ">
      <thead className="font-bold" >
        <tr>
          {headers.map((header, index) => (
            <th key={index} className="px-6 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">{header} </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.map((item, rowIndex) => (
          <tr key={rowIndex}>
            {headers.map((header, colIndex) => (
              <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-900" key={colIndex}>
                {header === 'signup_month'
                  ? new Date(item[header]).toLocaleDateString()
                  : header === 'upgrade_rate'
                  ? `${(item[header] * 100).toFixed(2)}%`
                  : item[header]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
};

export default DataTable;
