import React, { useState } from "react";
import firebase from "../firebase/firebaseConfig";
import { useParams } from 'react-router-dom';


const UnsubscribeForm = () => {
  const { uid } = useParams();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      setMessage("Please enter your email address.");
      return;
    }
    
    // You can replace this with an API call to handle the unsubscription
    console.log("Unsubscribing email: ", email);

    try {
      // Reference to the 'users' node in Firebase Realtime Database
      const usersRef = firebase.database().ref("users");
      const emailQuery = usersRef.orderByChild("email").equalTo(email);
      console.log(usersRef)

      // Get data based on the query
    
        
            console.log(uid)

           // Update the email_subscribe field to false
           firebase.database().ref(`users/${uid}/email_subscribe`).set(false)
           .then(() => {
             setMessage("You have successfully unsubscribed from our emails.");
             setEmail("");
           })
           .catch((error) => {
             console.error("Error updating subscription status: ", error);
             setMessage("An error occurred. Please try again.");
           });
   
        
   
    } catch (error) {
      console.error("Error unsubscribing: ", error);
      setMessage("An error occurred. Please try again.");
    }
    // Simulate a successful unsubscription
    setMessage(`You have successfully unsubscribed from our emails.`);
    setEmail("");
  };

  return (
    <div className="unsubscribe-form max-w-md mx-auto mt-16 p-4 bg-white shadow-md rounded-md">
      <h2 className="text-xl font-bold mb-4">Unsubscribe from Emails</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Email Address</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            required
            className="mt-1 p-2 border border-gray-300 rounded w-full"
            placeholder="Enter your email"
          />
        </div>

        {message && <p className="mb-4 text-green-600">{message}</p>}

        <button
          type="submit"
          className="w-full bg-[#F58F29] text-white py-2 px-4 rounded-md hover:bg-[#F9C14A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F58F29]"
        >
          Unsubscribe
        </button>
      </form>
    </div>
  );
};

export default UnsubscribeForm;
