import React, { useState , useEffect} from 'react';
import { useAuth } from '../hooks/useAuth';
import firebase from "../firebase/firebaseConfig";


const Profile = () => {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [industry,setIndustry] = useState("");
  const [company, setCompany] = useState("");
  const [Department, setDepartment] = useState("");
  const [Role, setRole] = useState("");
  const { user,loading } = useAuth();

  const handelSubmit = async(e) =>{
    const userRef = firebase.database().ref('users/'+ user.uid);
                await userRef.update({
                first_name:FirstName,
                last_name: LastName,
                department:Department,
                company:company,
                role:Role,
                industry:industry
                });
    console.log("User information is updated.")
              
  }

  useEffect(() => {
   setFirstName(user.first_name);
   setLastName(user.last_name);
   setIndustry(user.industry)
   setDepartment(user.department);
   setCompany(user.company)
   setRole(user.role)
  },[user]);
  

  return (
    <>
      <div className="flex justify-between items-center w-full mb-3">
        <h1>Profile</h1>
      </div>
      <div>
      <div className='bg-white p-8 max-w-md w-full'>
      <p className="text-sm font-bold text-gray-900 mb-5 ">Basic Information</p>
      <form className = "databaseForm" id="databaseForm-csv"  onSubmit={handelSubmit}>
                        <div className="flex items-center">
                            <label className="flex-shrink-0  text-sm font-medium text-gray-500 mt-1 mr-14 " htmlFor="name">First Name:</label>
                            <input type="text"  name="first-name" value={FirstName} onChange={(e) => setFirstName(e.target.value)} required/>
                        </div>

                        <div className="flex items-center">
                            <label className="flex-shrink-0  text-sm font-medium text-gray-500 mt-1 mr-14 " htmlFor="name">Last Name:</label>
                            <input type="text"  name="last-name" value={LastName} onChange={(e) => setLastName(e.target.value)} required/>
                        </div>

                        <div className="flex items-center">
                            <label className="flex-shrink-0  text-sm font-medium text-gray-500 mt-1 mr-14 " htmlFor="name">Industry:</label>
                            <select className="ml-4 mt-1 px-2 py-1 text-sm flex-1 border rounded-md shadow-sm" value={industry} onChange={e => setIndustry(e.target.value)}>
                              <option className='text-gray-400'  >Select Industry</option>
                              <option className='text-gray-400'  >Technology</option>
                              <option className='text-gray-400'  >Finance</option>
                              <option className='text-gray-400'  >Healthcare</option>
                              <option className='text-gray-400'  >Education</option>
                              <option className='text-gray-400'  >Retail</option>
                              <option className='text-gray-400'  >Manufacturing</option>
                              <option className='text-gray-400'  >Real Estate</option>
                              <option className='text-gray-400'  >Enterainment</option>
                              <option className='text-gray-400'  >Non-Profit</option>
                              <option className='text-gray-400'  >Others</option>
                          </select>
                        </div>

                        <div className="flex items-center">
                            <label className="flex-shrink-0  text-sm font-medium text-gray-500 mt-1 mr-14 " htmlFor="name">Company:</label>
                            <input  type="text"  name="company" value={company} onChange={(e) => setCompany(e.target.value)} required/>
                        </div>

                        
                        
                        <div className="flex items-center">
                          <label className="flex-shrink-0  text-sm font-medium text-gray-500 mt-1 mr-9 ">Department:</label>
                          <select className="ml-4 mt-1 px-2 py-1 text-sm flex-1 border rounded-md shadow-sm" value={Department} onChange={e => setDepartment(e.target.value)}>
                              <option className='text-gray-400'  >Select department</option>
                              <option className='text-gray-400'  >Engineering</option>
                              <option className='text-gray-400'  >Product</option>
                              <option className='text-gray-400'  >Marketing</option>
                              <option className='text-gray-400'  >Finance</option>
                              <option className='text-gray-400'  >Customer Success</option>
                              <option className='text-gray-400'  >Business Operation</option>
                          </select>
                      </div>

                      <div className="flex items-center">
                            <label className="flex-shrink-0  text-sm font-medium text-gray-500 mt-1 mr-14 " htmlFor="name">Role:</label>
                            <input  type="text"  name="role" value={Role} onChange={(e) => setRole(e.target.value)} required/>
                        </div>
                        
                        
                        <div className="relative mt-3 mb-3 flex items-center justify-between">
                                <button className="flex items-center bg-[#F58F29] border border-gray-200 p-1 mt-1 mb-1 rounded-md shadow w-50" type="submit" value="Update">
                                    <span className="text-white">Update</span>
                                </button>
                        </div>
                    </form>
      </div>

      <div className='bg-white p-8 max-w-md w-full'>
        <p className="text-sm font-bold text-gray-900 mb-5 ">Subscription</p>
        <p className="flex-shrink-0  text-sm font-medium text-gray-500 mt-1 mr-14 "> Your current subscription plan is: {user.planType} </p>
      </div>
      
      <div className='bg-white p-8 max-w-md w-full'>
        <p className="text-sm font-bold text-gray-900 mb-5 ">Teams</p>
        <p className="flex-shrink-0  text-sm font-medium text-gray-500 mt-1 mr-14 "> Teams feature is coming. </p>
      </div>

      </div>
    </>
  )
};

export default Profile;
