import React from "react";
import { useState } from "react";
import firebase from "../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import qquest_icon from "../assets/logo.png";


const Register = () => {
    const [email,setEmail] = useState("");
    const [first_name,setFirstName] = useState("");
    const [last_name,setLastName] = useState("");
    const [company, setCompany] = useState("");
    const [role, setRole] = useState("");
    const [password,setPassword] = useState("");
    const [repassword,setRePassword] = useState("");
    const [passwordError, setPasswordError] = useState('');
    const [userConsent, setUserConsent] = useState(true)

    const navigate = useNavigate();
    
    const handelSubmit = async(e) =>{
        e.preventDefault();

        try {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if(!emailRegex.test(email)){
            console.log("please provide a valid email");
            return
            }
  
            if (password !== repassword) {
                console.log("Passwords do not match.");
                setPasswordError("Passwords do not match.");
                return
            } else {
                setPasswordError('');
                // Proceed with form submission or further actions
                console.log("Password matches, proceed with form submission.");
            }
            
            const response = await firebase.auth().createUserWithEmailAndPassword(email, password);
            if(response.user){
                await response.user.updateProfile({
                displayName: email,
                })
                const uid = response.user.uid;
                const userRef = firebase.database().ref('users/'+ uid);
                await userRef.set({
                uid:uid,
                email: email,
                first_name:first_name,
                last_name:last_name,
                company:company,
                role:role,
                email_subscribe: userConsent
                });

                response.user.getIdToken().then(idToken => {

                  fetch('https://api.qquest.io/receive-shared-assistant-after-signup', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                     'Authorization': `Bearer ${idToken}`,
        
                    },
                    body: JSON.stringify({
                        user_id:uid,
                        user_email:email,
                        send_email:{
                          template:'welcome-email',
                          params: {
                            userName:first_name
                          }
                        }
                      
                    })
                  })
                  .then(res => res.json())
                  .then(data => {
                    console.log(data);
                  })
                  .catch(error => {
                    console.log(JSON.stringify(error));
                  });
                });

                
        
                navigate(`/login?email=${encodeURIComponent(email)}`);


                setEmail("");
                setPassword("");
                setRePassword("");
            }
        } catch (error) {
            console.log("Register error",error)
        }
        
    }


  return (
    <div className="flex items-center w-full mx-auto h-screen bg-[#FAFAFA]">
      <form onSubmit={handelSubmit} className="grid place-items-center lg:w-5/12 sm:w-9/12 w-11/12 mx-auto bg-white text-gray-800 shadow-2xl rounded-3xl">
                <div className='flex items-center mb-4'>
                  <img src={qquest_icon} alt='Qquest' className='w-16 pt-16 pb-4 mr-4'/>
                  <div className="pt-16 pb-4 text-3xl font-bold capitalize font-pt-sans text-black">Login To Qquest</div>
                </div>
        <div className="w-full flex items-center px-14 pb-8">
        <label className='flex-shrink-0 text-sm font-medium text-gray-800 mt-1 mr-28'>First Name</label>
          <input
            type="text"
            className="ml-4 mt-1 px-2 py-1 text-sm flex-1 border rounded-md mr-auto shadow-sm max-w-[542px]"
            placeholder=""
            required
            value={first_name}
            onChange={(e)=>setFirstName(e.target.value)}
          />
        </div>
        <div className="w-full flex items-center px-14 pb-8">
        <label className='flex-shrink-0 text-sm font-medium text-gray-800 mt-1 mr-28'>Last Name</label>
          <input
            type="text"
            className="ml-4 mt-1 px-2 py-1 text-sm flex-1 border rounded-md mr-auto shadow-sm max-w-[542px]"
            placeholder=""
            required
            value={last_name}
            onChange={(e)=>setLastName(e.target.value)}
          />
        </div>
        {/**** email ****/}
        <div className="w-full flex items-center px-14 pb-8">
        <label className='flex-shrink-0 text-sm font-medium text-gray-800 mt-1 mr-28'>Email</label>
          <input
            type="email"
            className="ml-4 mt-1 px-2 py-1 text-sm flex-1 border rounded-md mr-auto shadow-sm max-w-[542px]"
            placeholder="example@123.com"
            required
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
          />
        </div>
        {/***** password ****/}
        <div className="w-full flex items-center px-14 pb-8">
            <div className="flex items-center">
            <label className='flex-shrink-0 text-sm font-medium text-gray-800 mt-1'>Password</label>
                <div className="tooltip ml-2 mt-2">  {/* Added margin-left for spacing */}
                <span className="tooltip-icon mr-16 ml-1 text-sm font-medium text-gray-800">?</span>
                <span className="tooltiptext text-sm p-4">Password must be at least 8 characters long, including letters and numbers.</span>
                </div>
            </div>
            <input
                type="password"
                className="ml-4 mt-1 px-2 py-1 text-sm flex-1 border rounded-md mr-auto shadow-sm max-w-[542px]"
                placeholder="********"
                pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\S]{8,}$" 
                required
                value={password}
                onChange={(e)=>setPassword(e.target.value)}
            />
        </div>

        {/* Confirm Password */}
        <div className="w-full flex items-center px-14 pb-8">
          <label className='flex-shrink-0 text-sm font-medium text-gray-800 mt-1 mr-6' htmlFor="confirmPassword">Confirm Password</label>
            <input
                id="confirmPassword"
                type="password"
                className="ml-4 mt-1 px-2 py-1 text-sm flex-1 border rounded-md mr-auto shadow-sm max-w-[542px]"
                placeholder="********"
                required
                value={repassword}
                onChange={(e)=>setRePassword(e.target.value)}
            />
        </div>

        <div className="w-full flex items-center px-14 pb-8">
        <label className='flex-shrink-0 text-sm font-medium text-gray-800 mt-1 mr-28'>Company</label>
          <input
            type="text"
            className="ml-4 mt-1 px-2 py-1 text-sm flex-1 border rounded-md mr-auto shadow-sm max-w-[542px]"
            placeholder=""
            required
            value={company}
            onChange={(e)=>setCompany(e.target.value)}
          />
        </div>

        <div className="w-full flex items-center px-14 pb-8">
        <label className='flex-shrink-0 text-sm font-medium text-gray-800 mt-1 mr-28'>Role</label>
          <input
            type="text"
            className="ml-4 mt-1 px-2 py-1 text-sm flex-1 border rounded-md mr-auto shadow-sm max-w-[542px]"
            placeholder=""
            required
            value={role}
            onChange={(e)=>setRole(e.target.value)}
          />
        </div>
        


        <div className="w-full flex justify-between items-center px-14 pb-8 text-[#F9C14A]">
          <div className="text-sm text-gray-800 my-2">Already have an account?  
  
            <a href="/login" className="text-blue-600 cursor-pointer hover:underline ml-2">
               Login Now
            </a>
         </div>
        </div>
        {passwordError && <div style={{ color: 'red' }}>{passwordError}</div>}
        <div className="mx-auto flex justify-center items-center pt-6 pb-2">
          <button
            type="submit"
            className="flex items-center justify-center bg-[#F58F29] border border-gray-200 p-1 mt-1 mb-1 rounded-md shadow w-[200px]"
          >
           <p className="text-white"> Sign up </p>
          </button>
          
      
        </div>

        <div className="mx-auto flex justify-center items-center pt-1 pb-16">
            <input
              type="checkbox"
              name="termsAccepted"
              checked={userConsent}
              onChange={(e)=>setUserConsent(e.target.value)}
              required
            />
            <label className="text-sm text-gray-600 my-1 mx-1">
               I agree to the{" "}
              <a href="https://www.qquest.io/privacy-policy" target="_blank" className="text-blue-600 hover:text-blue-800 underline" rel="noopener noreferrer">  
                Privacy Policy
              </a>{" "}
              and{" "}
              <a href="https://www.qquest.io/term-condition" target="_blank"  className="text-blue-600 hover:text-blue-800 underline" rel="noopener noreferrer">
                Terms of Service
              </a>
            </label>
        </div>

      </form>
    </div>
  );
};

export default Register;