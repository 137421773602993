import { useCallback } from 'react';

const useUpdateAssistants = () => {
  const updateAssistants = useCallback(async (user, navigate, update_details, itemID = null) => {
    try {
      if (user && user.uid) {
        const response = await fetch('https://api.qquest.io/update-assistant-portal', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${user.token}`
          },
          body: JSON.stringify({
            user_id: user.uid,
            assist_id: itemID,
            update: update_details
          }),
        });
        
        return response.json();
      } else {
        navigate('/login');
      }
    } catch (error) {
      console.log("Error in updating the assistants:", error);
    }
  }, []);

  return updateAssistants;
};

export default useUpdateAssistants;
