import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/database"

const firebaseConfig = {
  apiKey: "AIzaSyDCTgLWV6Hy3SrVWze6U8dJxnXDtz1Tzvc",
  authDomain: "iron-century-413915.firebaseapp.com",
  projectId: "iron-century-413915",
  storageBucket: "iron-century-413915.appspot.com",
  messagingSenderId: "118763474022",
  appId: "1:118763474022:web:b0c90c7adeed0b103571a7",
  measurementId: "G-9TC5LGKG3Q"
};

// Initialize Firebase
if(!firebase.apps.length){
    firebase.initializeApp(firebaseConfig)
}

export default firebase