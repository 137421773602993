import { useCallback } from 'react';

const useUpdateModel = () => {
  const updateModel = useCallback(async (user, navigate, source_id) => {
    try {
      if (user && user.uid) {
        console.log("start syncing the model...")
        const response = await fetch('https://api.qquest.io/update-enriched-database-schema', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${user.token}`
          },
          body: JSON.stringify({
            user_id: user.uid,
            source_id: source_id
          }),
        });
        if (response.ok) {
            const data = await response.json(); // Parsing the JSON body of the response
            console.log("Response data:", data); // This will log the actual data or message from your server
        } else {
            console.log("HTTP Error:", response.status);
            const errorData = await response.text(); // Optionally fetch and log the error response
            console.log("Error details:", errorData);
        }
      } else {
        navigate('/login');
      }
    } catch (error) {
      console.log("Error in updating the models:", error);
    }
  }, []);

  return updateModel;
};

export default useUpdateModel;
