// ForgetPassword.jsx
import React, { useState } from 'react';
import firebase from '../firebase/firebaseConfig';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      await firebase.auth().sendPasswordResetEmail(email);
      setMessage('Password reset email sent! Check your inbox.');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <h1 className="text-2xl font-bold mb-5">Reset Password</h1>
      {message && <p className="text-green-500 mb-4">{message}</p>}
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handlePasswordReset}>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email address
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
        <button
          type="submit"
          
          className="w-full bg-[#F58F29] text-white py-2 px-4 rounded-md hover:bg-[#F9C14A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F58F29]"
        >
          Send Reset Email
        </button>
      </form>
    </div>
  );
};

export default ForgetPassword;
